<template>
    <div class="card">
        <div class="flex-column" style="gap: 8px; padding: 0px 16px 0px;">
            <div class="flex-row" style="align-items: baseline" >
                <span><h3>Hosted Competitions</h3></span>
                <Button @click='openEditCompetitionModal()' class="green-button__secondary action-button" style="margin-left:auto;">
                                        <span>New</span>
                                    </Button>
            </div>
             <LoadingSpinner :loading='loading' v-if='loading'/>
            <div v-if="!loading">
                <GroupCompetitionsList :loading="loading" :competitions="competitions" @edit-competition="openEditCompetitionModal" @cancel-competition="openCancelConfirmationModal"
                    @change-page="onChangePage" :limit="limit" :offset="offset" :total="total"/>
              
            </div>
        </div>
        
        
    </div>
    <CreateEditCompetitionModal ref="createEditModal" @competition-updated="refreshCompetitions"/>
    <GroupCompetitionPaymentConfirmationModal ref="paymentConfirmationModal"  @competition-payment-confirmed="onCompetitionPaymentConfirmed"/>
     <ConfirmModal headerText="Cancel Competition"  ref="confirmCancelModal" @confirmed="onCancelConfirmed">
            <template #content>
                <div class="confirm-container"> {{confirmCancelText}}</div>

                
            </template>
        </ConfirmModal>
</template>

<script>
import GroupService from '../../service/GroupService';
import GroupCompetitionPaymentConfirmationModal from './modal/GroupCompetitionPaymentConfirmationModal.vue';

import LoadingSpinner from '../common/LoadingSpinner.vue';
import GroupCompetitionsList from './GroupCompetitionsList.vue';
import CreateEditCompetitionModal from './modal/CreateEditCompetitionModal.vue';

import ConfirmModal from '../modal/ConfirmModal.vue';

export default {
    name: 'GroupCompetitionsCard',
   
    components: { 
        LoadingSpinner,
        GroupCompetitionsList,
        CreateEditCompetitionModal,
        GroupCompetitionPaymentConfirmationModal,
        ConfirmModal,
    },
    props: {
        selectedGroup: {
            type: Object,
            required: true
        },
    },

    data() {
        return {
          
           
           loading: false,
           competitions: [],
           selectedCompetition: null,
           confirmCancelText: null,
           limit: 5,
           offset: 0,
           total: 0
           
        };
    },

    mounted() {
        this.selectedCompetition = null;
        if( this.justAfterCompetitionPaymentComplete() ){
            this.waitForPaymentConfirmation();
        }
        else {
            if( this.selectedGroup ){
                this.loadHostedCompetitions();
            }
        }
        
       

       
    },

    methods: {
         onChangePage(pageState){
            console.log("got change page event with page state of ", pageState)
            this.offset = pageState.first;
            
            this.loadHostedCompetitions();
        },
        openCancelConfirmationModal(comp){
            this.selectedCompetition = comp;
            this.confirmCancelText = "Are you sure you want to cancel the competition "+comp.name+"?";
            this.$refs.confirmCancelModal.open();
        },

        onCancelConfirmed() {
            GroupService.cancelCompetition(this.selectedCompetition).then(resp => {
                if( resp.data.status == "success"){
                     this.$toast.add({ severity: 'success', summary: `The competition was cancelled.`, life: 3500, group: "center"});
                     this.loadHostedCompetitions();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: `There was an error attempting to cancel competition.  Contact support if the problem persists.`, life: 3500, group: "center"});
                    console.log("error canceling ", resp.data);
                }
            }).catch(err=> {
                this.$toast.add({ severity: 'error', summary: `There was an error attempting to cancel competition.  Contact support if the problem persists.`, life: 3500, group: "center"});
                console.log("error canceling ", err);
            })
        },

        onCompetitionPaymentConfirmed() {
             if( this.selectedGroup ){
                this.loadHostedCompetitions();
            }
        },

         waitForPaymentConfirmation() {
            //this.$route.params.groupId
            this.$nextTick( () => {
                 this.$refs.paymentConfirmationModal.open(this.$route.query.contestId);
            });
        },

        justAfterCompetitionPaymentComplete(){
            //console.log("params=", this.$route.params);
            return (this.$route.query.contestId && this.$route.query.payment && this.$route.query.payment == 'complete');
        },

        openEditCompetitionModal(comp) {//eslint-disable-line
            this.$refs.createEditModal.open(comp);
        },

        refreshCompetitions() {
            this.loadHostedCompetitions();
        },

        loadHostedCompetitions() {
            this.loading = true;

            GroupService.getHostedCompetitions(this.selectedGroup.groupId, this.limit, this.offset).then(resp => {
                this.loading = false;
                if (resp.data.status == "success") {

                    this.competitions = resp.data.contests.data;
                    this.total = resp.data.contests.totalCount;
                    // console.log("getGroupsHostedContests", this.competitions);

                    
                }
            });
           
        }
    },
};
</script>

<style scoped>
.confirm-container {
   

    margin: 12px;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}
.flex-row {
    display: flex;
    flex-direction: row;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
}

.settings-section {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
}
.settings-section:last-child {
    margin-bottom: 0;
}

.settings-title {
    margin: 24px 0 0 0;
}
.settings-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* color: #bfbfbf; */
}
.settings-label {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
}
.view-blocked-users-button {
    width: fit-content;
    background: none !important;
    border: none;
    padding: 0 !important;
    font-weight: 400;
    font-size: 14px;
    color: #32364e;
    text-decoration: underline;
}
.view-blocked-users-button:hover {
    color: black;
}

.action-button {
    padding: 6px 16px;
    border-style: solid;
    border-radius: 16px;
    text-align: center;
    width: 80px;
    /* margin-left: 5px; */
}

.action-button span {
    width: 100%;
}
</style>