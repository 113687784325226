<template>
    <Dialog header="Payment Required" v-model:visible="display" :modal="true" class='group-comp-modal' >
        
         
            <LoadingSpinner :loading='loading' v-if='loading'/>
            <div v-if='!loading'>
                
                <span class="settings-description">
                  
                   <p v-if="errorMessage == null">To host this competition you will have to pay {{price}} {{currency}}</p>
                    <p v-if="errorMessage != null">{{errorMessage}}</p>
                       
                   
                </span>
            </div>
        

		<template #footer>
            
			<Button label="Cancel Competition" class='p-button-raised p-button-rounded' @click="cancelCompetition()"/>
            <Button label="Continue to Payment" class='p-button-raised p-button-rounded' @click="continueToPayment()" :disabled="errorMessage != null" :loading="paying"/>
            
           
		</template>
	</Dialog>
</template>

<script>


import Dialog from 'primevue/dialog';

import PaymentService from '../../../service/PaymentService';
import GroupService from '../../../service/GroupService';

import LoadingSpinner from '../../common/LoadingSpinner.vue';
import FormattingUtils from '../../../utilities/FormattingUtils';



// import Textarea from 'primevue/textarea';


export default {
    name: 'GroupCompetitionPaymentModal',
 
    components: {
        Dialog, 
        LoadingSpinner
    },

    
    data() {
		return {
           formatter: new FormattingUtils(),
			display: false,
            loading: false,
            
            groupContest: null,
            errorMessage: null,
            paying: false,
            checkoutUrl: null,
            price: null,
            currency: null,
           
           
		}
    },

   

   

    methods: {
        

        open(groupContest) {
            this.display = true;
            
            this.groupContest = groupContest;
            this.cancelling = false;
            this.paying = false;
            this.errorMessage = null;
            this.loading = true;
            PaymentService.getGroupCompetitionProduct().then(resp => {
                this.loading = false;
                if( resp.data.status == "success"){
                    this.price = this.formatter.format(resp.data.product.price, '$0.00');
                    this.currency = (resp.data.product.currencyId == 1 ? 'CAD' : 'USD');
                }
                else {
                    console.log("no group hosting product found...config issue");
                    this.errorMessage = "You cannot currently purchase any hosted competitions.  Contact support for further information."
                }
            }).catch(err=> {
                this.loading = false;
                 this.errorMessage = "You cannot currently purchase any hosted competitions.  Contact support for further information."
                console.log("Error getting group hosting product", err);
            })
           
           
        },

        close() {
            this.display = false;
           
            this.groupContest = null;
            this.paying = false;
            this.cancelling = false;
           
            this.errorMessage = null;

        },

        cancelCompetition(){
             this.cancelling = true;
             GroupService.cancelCompetition(this.groupContest).then(resp =>{
                 if( resp.data.status == "success") {
                    console.log("Competition cancelled");
                 }
                 else {
                    console.log("error cancelling group comp", resp.data);
                 }
                 this.close();
             }).catch(err => {
                console.log("error cancelling group comp", err);
                this.close();
             })

        },

       continueToPayment() {
           
                    
            this.paying = true;
            PaymentService.createGroupCompetitionCheckoutSession(this.groupContest).then( resp => {
                    
                if( resp.data.status == "success") {
                    sessionStorage.setItem('showTourPrompt', false);
                    window.location.href = resp.data.url;
                }
                else {
                    this.paying = false;
                    this.$toast.add({ severity: 'error', summary: 'Error creating checkout session. Contact us if the issue persists.', detail: '', life: 5000,  group: 'center'  });
                     console.log("error creating group comp hosting checkout session ", resp.data);
                }
            }).catch(err => {
                this.paying = false;
                    this.$toast.add({ severity: 'error', summary: 'Error creating checkout session.', detail: "If the problem persists contact support@invrs.com.", life: 5000, group: 'center'});
                    console.log("error creating group comp hosting checkout session ", err);
            })
            
           
       }

       
    }
}
</script>

<style>
.group-comp-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 550px;
}


.group-comp-modal .p-dialog-content {
    padding: 0px 18px 0px;
}

.group-comp-modal .p-dialog-footer {
    padding: 34px 0px;
    text-align: center;
}
</style>
<style scoped>
 *:not(.pi), ::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
} 

.settings-section {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    width: 400px;
}
.settings-section:last-child {
    margin-bottom: 0;
}

.settings-title {
    margin: 24px 0 0 0;
}
.settings-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* color: #bfbfbf; */
}
.settings-label {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
    padding: 16px 0px 16px 16px;
    text-align: center;
}

.header-highlight {
    color: #693BF5;
}

.body-text {
    text-align: center;
    font-size: 18px;
    color: #32364e;
    margin: 12px 0px 30px;
}

.perks-container {
    text-align: center;
}

.perk-container {
    margin-bottom: 16px;
}
.perk-container i {
    color: #33CC99;
}
.perk-container span {
    color: black;
    font-size: 18px;
}
.perk-container:last-child {
    margin-bottom: 0px;
}

.subscribe-button{
    background-color: #fff;
    color: #32364e;
    border: 1px solid #32364e;
    font-weight: normal;

    font-size: 14px;
    font-weight: bold;
    padding: 8px 14px;
    margin: 0px;
}

.subscribe-button:hover {
    background-color: #33CC99;
    border: 1px solid #33CC99;
}


.upgrade-button, .view-button {
    font-size: 18px;
    font-weight: bold;
    padding: 8px 64px;
    margin: 0px;
}

.view-button {
    background-color: #fff;
    color: #32364e;
    border: 1px solid #32364e;
    font-weight: normal;
}

.view-button:hover {
    background-color: #32364e;
    border: 1px solid #32364e;
}

/* .p-button:last-child {
    margin-right: 0 !important;
} */
</style>