<template>
    <Dialog :header="dialogHeader" v-model:visible="display" :modal="true" class='create-edit-comp-modal'
        :draggable='false' @show='onShown'>
       
        
        
           
            <div class="row">
                <div class="column">
                    <span>Name:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Name for competition`"></i>
                    </div>
                </div>
                <div class="column">
                    <InputText class="input-text" v-model="name" style="margin-left:auto;"/>
                </div>
            </div>
             <div class='portfolio-textarea-container'>
                
                <span class="p-float-label" style="margin-bottom:5px;">
                    <span>Short Description:</span>
                   
                    <i class="pi pi-info-circle " style="color:#33cc99;margin-left:3px;"
                        v-tooltip.bottom="`A brief description of the competition.  Max 300 chars.`"></i>
                
                   
                </span>
                <Textarea id="compShortDescription" rows="3" cols="30" v-model='shortDescription' />
            </div>
             <div class='portfolio-textarea-container'>
               
                <span class="p-float-label" style="margin-bottom:5px;margin-top:10px;">
                    <span>Details and Conditions:</span>
                    <i class="pi pi-info-circle"  style="color:#33cc99;margin-left:3px;"
                        v-tooltip.bottom="`Details about the competition, including rules, disclaimers and eligibility rules.  This is shown to users before registration.`"></i>
                    
                   
                   
                </span>
                <Textarea id="compLongDescription" rows="10" cols="34" v-model='longDescription' />
            </div>
            <div class="row">
                <div class="column">
                    <span>Icon:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`The icon shown in the competition listing.(60 x 60 pixels best)`"></i>
                    </div>
                </div>
                <div class='column avatar-container' style=" display: flex; flex-direction: row;">
                    <div class="p-avatar p-component p-avatar-image lg rounded-square" style="min-width:60px;max-width:60px;" v-show="imageUrl" >
                        
                        <img id='compImage' :src='imageUrl'  />
                        
                        <span  v-show="!imageUrl"> No Image</span>
                    </div>
                    <div style="margin-left:5px;">
                        <Button icon="pi pi-trash" v-show='imageUrl' @click='removeImportedImage' style="margin-right:5px;" v-tooltip.bottom="`Remove current image.`"/>
                        <Button icon="pi pi-search"  @click='openFileDialogForImportingImage' v-tooltip.bottom="`Choose Image.`"/>
                        <!-- <span class='p-button p-component action-button' icon="pi pi-search" @mouseup='openFileDialogForImportingImage'> -->
                            
                            <ImageFileInput srcImageId='compImage' @selectedImage='selectedImage($event)' @openErrorModal='openErrorModal($event)'/>
                        <!-- </span> -->
                    
                        
                    </div>
                </div>
            </div>

            <div class="row" >
                <div class="column">
                    <span>Members Only:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Only members of this group can register.`"></i>
                    </div>
                </div>
                <div class="column">
                    <InputSwitch v-model="membersOnly" class="create-portfolio-switch" />
                </div>
            </div>
            <div class="row" >
                <div class="column">
                    <span>Private:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Only registered competitors will be able to see competition stats.`"></i>
                    </div>
                </div>
                <div class="column">
                    <InputSwitch v-model="privateContest" class="create-portfolio-switch" />
                </div>
            </div>
            <div class="row" >
                <div class="column">
                    <span>Invite Only:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Only users with a registration code can register.`"></i>
                    </div>
                </div>
                <div class="column">
                    <InputSwitch v-model="inviteOnly" class="create-portfolio-switch" />
                </div>
            </div>
            <div class="row" v-if="inviteOnly">
                <div class="column">
                    <span>Registration code:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Only users with this registration code can register.(Max 50 chars)`"></i>
                    </div>
                </div>
                <div class="column">
                    <InputText class="input-text" v-model="registrationCode" style="margin-left:auto;" />
                </div>
            </div>
            <div class="row" >
                <div class="column">
                    <span>Registration Start/End dates:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Users can only register during this period.  Start time will be start of day and End time will be end of day.`"></i>
                    </div>
                </div>
                <div class="column">
                    <Calendar  dateFormat="yy/mm/dd" showIcon v-model="registrationDateRange" :minDate="minDate"
                        selectionMode="range" :manualInput="false" placeholder="Select start and end dates." style="margin-left:auto;" :disabled="hasEnded()"/>
                </div>
            </div>
            <div class="row" >
                <div class="column">
                    <span>Competition Start/End dates:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`When the competition starts and ends. Start time will be market open and End time will be market close.`"></i>
                    </div>
                </div>
                <div class="column">
                    <Calendar dateFormat="yy/mm/dd" showIcon v-model="competitionDateRange" :minDate="minDate"
                        selectionMode="range" :manualInput="false" placeholder="Select start and end dates." style="margin-left:auto;" :disabled="hasEnded()"/>
                </div>
            </div>
           
            <div class="row">
                <div class="column">
                    <span>Starting Cash Balance:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`The total starting cash balance for all competition portfolios.  (max $1,000,000.00)`"></i>
                    </div>
                </div>
                <div class="column">
                   
                    <InputNumber placeholder="$0.00" v-model="minStartingCash" mode="currency" :currency="currency" style="margin-left:auto;" :disabled="hasStarted()"/>
                    <SelectButton v-model="currency" :options="['USD', 'CAD']" aria-labelledby="basic" style="margin-left:auto;" :disabled="hasStarted()"/>
                    
                </div>
            </div>
             <div class="row">
                <div class="column">
                    <span>Max Contestants:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`The maximum number of contestants allowed.(optional)`"></i>
                    </div>
                </div>
                <div class="column">
                   
                    <InputNumber v-model="maxContestants" :maxFractionDigits="0" style="margin-left:auto;" />
                    
                </div>
            </div>
            <div class="row" >
                <div class="column">
                    <span>Resets Allowed:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Allows user to reset their portfolio to its starting state for a fee.`"></i>
                    </div>
                </div>
                <div class="column">
                    <InputSwitch v-model="resetsAllowed" class="create-portfolio-switch" :disabled="hasEnded()"/>
                </div>
            </div>
            <div class="row" >
                <div class="column">
                    <span>Level2 Allowed:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Allows users with premium subscriptions or passes to view level 2 competition trade data. This is meant to mimic the real world scenario where users have access to Level 2 data as well as historical trades.(recommended)`"></i>
                    </div>
                </div>
                <div class="column">
                    <InputSwitch v-model="allowLevel2" class="create-portfolio-switch" :disabled="hasEnded()"/>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <span>Minimum Short Price:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Short positions only permitted for stocks that have a price greater than or equal to this amount.(optional)`"></i>
                    </div>
                </div>
                <div class="column">
                   
                    <InputNumber placeholder="$0.00" v-model="minShortPrice" mode="currency" :currency="currency" style="margin-left:auto;" :min="0"/>
                    
                    
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <span>Minimum Long Price:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Long positions only permitted for stocks that have a price greater than or equal to this amount.(optional)`"></i>
                    </div>
                </div>
                <div class="column">
                   
                    <InputNumber placeholder="$0.00" v-model="minLongPrice" mode="currency" :currency="currency" style="margin-left:auto;" :min="0" />
                    
                    
                </div>
            </div>

            <div class="row" >
                <div class="column">
                    <span>Trading Delay:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`To avoid front running when using delayed price information, all trades have a random delay applied before being executed.(recommended)`"></i>
                    </div>
                </div>
                <div class="column">
                    <InputSwitch v-model="tradingDelay" class="create-portfolio-switch" />
                </div>
            </div>

            
           
           
       
        <template #footer>
            <Button class='footer-portfolio-button' label="Cancel" @click='close()' />
            <Button class='footer-portfolio-button' label="Save" :disabled='!enableSaveButton'
                :loading='loading' @click='saveCompetition' />
        </template>
    </Dialog>
     <ImportImageErrorModal ref='importImageErrorModal'/>
     <GroupCompetitionPaymentModal ref="paymentModal"/>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';

import Textarea from 'primevue/textarea';
import SelectButton from 'primevue/selectbutton';

import InputNumber from 'primevue/inputnumber';

import GroupService from '../../../service/GroupService';


import { mapState } from 'vuex';
import Calendar from 'primevue/calendar';
import GroupCompetitionPaymentModal from './GroupCompetitionPaymentModal';


import CustomValidationUtils from '../../../utilities/CustomValidationUtils';

import moment from "moment";

import ImageFileInput from '../../fileInput/ImageFileInput.vue';

import EventBus from '../../../event-bus';
import { APP_URL } from '../../../common/config';


import { isAfter} from 'date-fns';
import ImportImageErrorModal from '../../errors/ImportImageErrorModal.vue';

export default {
    name: 'CreateEditGroupPortfolioModal',
    emits:['competition-updated'],
    // props: {
	// 	selectedCompetition: {
	// 		type: Object,
	// 		required: false
	// 	},
    // },

    data() {
        return {
           
            display: false,
            competition: null,
            loading: false,
            name: null,
            shortDescription: null,
            longDescription: null,
            membersOnly: false,
            privateContest: false,
            inviteOnly: false,
            registrationCode: null,
            minDate: new Date(),
            registrationDateRange: [],
            competitionDateRange: [],
            currency: 'USD',
            minStartingCash: null,
            resetsAllowed: true,
            minShortPrice: null,
            minLongPrice: null,
            tradingDelay: true,
            imageFile: null,
            imageUrl: APP_URL+'/assets/images/competition-small.png',
            maxContestants: null,
            allowLevel2: true,






        }
    },
    components: {
        Dialog,
        InputText,
        Textarea,
        Calendar,
        InputSwitch,
        SelectButton,
        
        InputNumber,
        ImageFileInput,
        ImportImageErrorModal,
        GroupCompetitionPaymentModal,
    },

    computed: {
        ...mapState(['selectedGroup']),

        dialogHeader() {
            if(this.competition){
                return "Edit Competition";
            } else {
                return "New Competition";
            }
        },

        enableSaveButton() {
            return(
                this.name != null &&
                (this.registrationDateRange != null && this.registrationDateRange.length == 2) &&
                (this.competitionDateRange != null && this.competitionDateRange.length == 2) &&
                (this.minStartingCash != null && this.minStartingCash > 1.00) &&
                (this.shortDescription != null) &&
                (!this.inviteOnly || (this.inviteOnly && this.registrationCode != null))
            );
            
        }

       
    },

    created() {
       
    },

    methods: {

        showPaymentRequired(comp) {
            this.$refs.paymentModal.open(comp);
        },

       saveCompetition() {
        //console.log("in save competition")
            if( this.validParams() ){
                //console.log("parameters valid")
                if( this.competition ){
                    let compUpdate = {
                        contestId: this.competition.contestId,
                        
                        membersOnly : this.membersOnly,
                        
                        startDate: this.competitionDateRange[0],
                        endDate: this.competitionDateRange[1],
                        registrationStartDate: this.registrationDateRange[0],
                        registrationEndDate: this.registrationDateRange[1],
                        name: this.name,
                        shortDescription: this.shortDescription,
                        currency: this.currency,
                        minStartingCash: this.minStartingCash,
                        inviteOnly: this.inviteOnly,
                        privateContest: this.privateContest,
                        longDescription: this.longDescription,
                        registrationCode: this.registrationCode,
                        resetsAllowed: this.resetsAllowed,
                        minShortPrice: this.minShortPrice,
                        minLongPrice: this.minLongPrice,
                        tradingDelay: this.tradingDelay,
                        icon: null,
                        maxContestants : this.maxContestants,
                        allowLevel2: this.allowLevel2,

                    }
                    if( this.imageFile ){
                        compUpdate.icon = this.imageFile;
                    }
                    else if( this.imageUrl ){
                        compUpdate.icon = this.createIconFromUrl(this.imageUrl);
                    }

                    this.loading = true;
                     GroupService.saveCompetition(this.selectedGroup.groupId, compUpdate).then(resp => {
                        this.loading = false;
                        if( resp.data.status == 'success'){
                            console.log("saved");
                            this.close();
                            this.$emit("competition-updated");
                        }
                        else if( resp.data.message == 'Invalid Name'){
                            this.showError("Invalid name.");
                        }
                        else {
                            console.log("error saving competition", resp.data.message);
                            this.showError("There was an unexpected error saving the competition.  Please contact support if the problem persists.");
                        }
                    }).catch( err=>{
                        console.log("error saving competition", err);
                        this.showError("There was an unexpected error saving the competition.  Please contact support if the problem persists.");
                    });
                }
                else {
                    let newComp = {
                       
                       
                        membersOnly : this.membersOnly,
                       
                        startDate: this.competitionDateRange[0],
                        endDate: this.competitionDateRange[1],
                        registrationStartDate: this.registrationDateRange[0],
                        registrationEndDate: this.registrationDateRange[1],
                        name: this.name,
                        shortDescription: this.shortDescription,
                        currency: this.currency,
                        minStartingCash: this.minStartingCash,
                        inviteOnly: this.inviteOnly,
                        privateContest: this.privateContest,
                        longDescription: this.longDescription,
                        registrationCode: this.registrationCode,
                        resetsAllowed: this.resetsAllowed,
                        minShortPrice: this.minShortPrice,
                        minLongPrice: this.minLongPrice,
                        tradingDelay: this.tradingDelay,
                        icon: null,
                        maxContestants : this.maxContestants,
                        allowLevel2: this.allowLevel2,

                    }

                    if( this.imageFile ){
                        newComp.icon = this.imageFile;
                    }
                    else if( this.imageUrl ){
                        newComp.icon = this.createIconFromUrl(this.imageUrl);
                    }
                    this.loading = true;
                    GroupService.saveCompetition(this.selectedGroup.groupId, newComp).then(resp => {
                        this.loading = false;
                        if( resp.data.status == 'success'){
                            let savedComp = resp.data.contest;
                            if( savedComp.statusId == 2){ // pending payment
                                this.showPaymentRequired(savedComp);
                                this.close();
                            }
                            else {
                                this.close();
                                this.$emit("competition-updated");
                            }
                        }
                        else if( resp.data.message == 'Invalid Name'){
                            this.showError("Invalid name.");
                        }
                        else {
                            console.log("error saving competition", resp.data.message);
                            this.showError("There was an unexpected error saving the competition.  Please contact support if the problem persists.");
                        }
                    }).catch( err=>{
                        console.log("error saving competition", err);
                        this.showError("There was an unexpected error saving the competition.  Please contact support if the problem persists.");
                    });
                }
                
            }
            
       },

       createIconFromUrl(url){
            let name = url.substring(url.lastIndexOf('/')+1);
            if( name.indexOf('?') > 0 ) { //trim off any ? from name
                name = name.substring(0, name.indexOf('?'));
            }
             if( name ) {
                if( name.length > 15){
                    name = name.substring(0, 14);
                }
                name = name.replaceAll('+', '');
                name = name.replaceAll('/', '');
                name = name.replaceAll('=', '');
            }
            return { name: name, url: url};
                
        },


       validParams() {
        if( !CustomValidationUtils.validTitle(this.name) ) {
            this.showError("Invalid name.");
            return false;
        }
        let validRegDates = this.validateRegistrationDates();
        if( !validRegDates.status){
            this.showError(validRegDates.msg)
            return false;
        }
        let validCompDates = this.validateCompetitionDates();
        if( !validCompDates.status) {
            this.showError(validCompDates.msg);
            return false;
        }
        if( this.minStartingCash == null || this.minStartingCash < 1.00){
            this.showError("Invalid starting cash balance.");
            return false;
        }

        if( this.shortDescription.length > 300){
            this.showError("Short Description too long.")
            return false;
        }

        return true;
        


       },

       validateRegistrationDates() {
            if( this.registrationDateRange == null || this.registrationDateRange.length <2){
                return {status:false, msg:"Invalid registration dates."};
            }
            let startDate = this.registrationDateRange[0];
            let endDate = this.registrationDateRange[1];
            if( this.competitionDateRange && this.competitionDateRange.length == 2){
                if( moment(endDate).isAfter(this.competitionDateRange[1])){
                    return {status: false, msg:"Invalid registration end date. Must be before competition end date."}
                }
                if( moment(startDate).isAfter(this.competitionDateRange[1])){
                    return {status: false, msg:"Invalid registration start date.  Must be before competition end date."}
                }
            }

            return {status:true};

           

       },

       validateCompetitionDates() {
            if( this.competitionDateRange == null || this.competitionDateRange.length <2){
                return {status:false, msg:"Invalid competition dates."};
            }
            let startDate = this.competitionDateRange[0];
            let endDate = this.competitionDateRange[1];
            let limit = moment(startDate).add(6, 'M');
            if( moment(endDate).isAfter(limit) ){
                return { status: false, msg:'Invalid competition dates.  Maximum duration of 6 months allowed.'};
            }

            return {status:true};
       },

       showError(msg){
        console.log("showing errr: "+ msg)
        this.$toast.add({ severity: 'error', summary: msg, life: 3500, group: 'center'});
       },

        onShown() {
           
        },

        open(competition) {
            this.minDate = new Date();
            this.imageFile = null;
            this.imageUrl = APP_URL+'/assets/images/competition-small.png';
            if (competition != null ){
                this.competition = competition;
                this.membersOnly = competition.membersOnly;
                
                this.competitionDateRange =  [ new Date(competition.startDate), new Date(competition.endDate)],
                this.registrationDateRange = [ new Date(competition.registrationStartDate), new Date(competition.registrationEndDate)]
                this.name = competition.name;
                this.imageUrl = competition.iconUrl;
                this.shortDescription = competition.shortDescription;
                this.longDescription = competition.longDescription;
                this.inviteOnly = competition.inviteOnly;
                this.currency = (competition.currencyId == 1? 'CAD' : 'USD');
                this.minStartingCash = competition.minStartingCash;
                this.privateContest = competition.privateContest;
                this.registrationCode = competition.registrationCode;
                this.resetsAllowed = competition.resetsAllowed;
                this.minShortPrice  = competition.minShortPrice;
                this.minLongPrice = competition.minLongPrice;
                this.tradingDelay = competition.maxTradingDelay > 0;
                this.maxContestants = competition.maxContestants;
                this.allowLevel2 = competition.allowLevel2;
                
            }
            else {
                this.competition = null;
                this.name =  null
                this.shortDescription= null;
                this.longDescription = null;
                this.membersOnly = false;
                this.privateContest =  false;
                this.inviteOnly = false;
                this.registrationCode =  null;
                this.registrationDateRange= [];
                this.competitionDateRange =  [];
                this.currency =  'USD';
                this.minStartingCash = null;
                this.resetsAllowed= true;
                this.minShortPrice = null;
                this.minLongPrice =  null;
                this.tradingDelay =  true;
                this.maxContestants = null;
                this.allowLevel2 = true;
            }
            this.loading = false;
            this.display = true;
           

            
        },

        

        close() {
            this.display = false;
            this.membersOnly = false;
            this.competitionDateRange = [];
            this.registrationDateRange = [];
            this.name = null;
            this.shortDescription = null;
            this.currency = 'USD';
            this.minStartingCash = null;
            this.inviteOnly = false;
            this.privateContest = false;
            this.longDescription = null;
            this.registrationCode = null;
            this.resetsAllowed = true;
            this.minShortPrice = null;
            this.minLongPrice = null;
            this.tradingDelay = true;
            this.competition = null;
            this.imageFile = null;
            this.imageUrl = APP_URL+'/assets/images/competition-small.png';
            this.maxContestants = null;
            this.allowLevel2 = true;
        },

        openFileDialogForImportingImage() {
            EventBus.emit('open-file-dialog');
        },
        selectedImage(importedImage) {
            this.imageFile = importedImage;
            this.imageUrl = importedImage.url;
        },
        openErrorModal(errorMessage) {
            this.$refs.importImageErrorModal.open(errorMessage)
        },

         removeImportedImage() {
            document.getElementById('compImage').src = APP_URL+'/assets/images/competition-small.png'; // Prevents former image from rendering when uploading new image
            this.imageFile = null;
            this.imageUrl = APP_URL+'/assets/images/competition-small.png';
        },
        

       
        hasEnded(){
            if( this.competition ){
                const now = new Date();
                var b = isAfter(now, new Date(this.competition?.endDate));
                
                return b ;
            }
            else {
                return false;
            }
        },

        hasStarted() {
            if( this.competition ){
                const now = new Date();
                var b = isAfter(now, new Date(this.competition?.startDate));
                
                return b ;
            }
            else {
                return false;
            }
        }
        

        

       
    },

    
}
</script>

<style>
.create-edit-comp-modal {
    position: static;
    font-family: "Trebuchet MS", "Verdana";
    width: 665px;
    /* max-height: 98% !important; */
    border-radius: 16px;
}

.create-edit-comp-modal .p-dialog-header {
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.create-edit-comp-modal .p-dialog-header .p-dialog-title {
    font-size: 24px;
    font-weight: bold;
}

/* .create-edit-comp-modal .p-dialog-content {
    height: 100%;
    overflow-y: hidden;
    padding: 0 16px;
} */

.create-edit-comp-modal .p-dialog-footer {
    text-align: center;
    padding: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
</style>
<style scoped>

.create-edit-comp-modal .p-dialog-content .avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5px;
 }

  .create-edit-comp-modal .p-dialog-content .avatar-container .rounded-square {
    display: flex;
    width: 60px;
    height: 60px;
    overflow: hidden;
    border: 3px solid #32364e;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
   
    border-radius: 16px;
}

 .create-edit-comp-modal .p-dialog-content .avatar-container .profile-picture-button-container {
    display: flex;
    margin-bottom: 30px;
}

.create-edit-comp-modal .p-dialog-content .avatar-container .profile-picture-button-container .delete:enabled:focus,
.create-edit-comp-modal .p-dialog-content .avatar-container .profile-picture-button-container .delete {
    border-color: #E63E3E;
    margin-right: 10px;
}
 .create-edit-comp-modal .p-dialog-content .avatar-container .profile-picture-button-container .delete:hover {
    background: #E63E3E;
    border-color: #E63E3E;
}




.field-radiobutton {
    margin-bottom: 24px;
    padding: 0px 80px;
    display: flex;
    justify-content: space-around;
}

.field-radiobutton .p-radiobutton {
    margin-right: 4px;
}

/* Shaking animation */
@keyframes shakeError {
    0% {
        transform: translateX(0);
    }

    15% {
        transform: translateX(0.375rem);
    }

    30% {
        transform: translateX(-0.375rem);
    }

    45% {
        transform: translateX(0.375rem);
    }

    60% {
        transform: translateX(-0.375rem);
    }

    75% {
        transform: translateX(0.375rem);
    }

    90% {
        transform: translateX(-0.375rem);
    }

    100% {
        transform: translateX(0);
    }
}

#quorumInputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.p-inputnumber,
.p-inputnumber :first-child,
.p-inputnumber .p-inputtext {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
}

.p-dropdown {
    min-width: 110px;
}

.portfolio-name-input-container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
}

.portfolio-name-input-container .p-inputtext {
    font-size: 16px;
    width: 100%;
    padding: 8px 8px 8px 16px;
    height: 36px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.portfolio-name-input-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}

.portfolio-name-input-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}

.portfolio-name-input-container>label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}

.portfolio-desc-textarea-container {
    margin-top: 24px;
}

.portfolio-desc-textarea-container .p-inputtextarea {
    font-size: 16px;
    resize: none;
    width: 100%;
    padding: 8px 8px 8px 16px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.portfolio-desc-textarea-container .p-inputtextarea::-webkit-scrollbar {
    /* width */
    width: 8px;
}

.portfolio-desc-textarea-container .p-inputtextarea::-webkit-scrollbar-thumb {
    background-color: #BFBFBF;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 9px solid #BFBFBF;
    /* creates padding around scroll thumb */
}

.portfolio-desc-textarea-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}

.portfolio-desc-textarea-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}

.portfolio-desc-textarea-container>label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}

.portfolio-desc-textarea-container .portfolio-desc-counter {
    font-size: 12px;
}

.portfolio-textarea-container .p-inputtextarea {
    resize: none;
    width: 100%;
    padding: 8px 8px 8px 16px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.portfolio-textarea-container .p-inputtextarea::-webkit-scrollbar {
    /* width */
    width: 8px;
}

.portfolio-textarea-container .p-inputtextarea::-webkit-scrollbar-thumb {
    background-color: #BFBFBF;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 9px solid #BFBFBF;
    /* creates padding around scroll thumb */
}

.portfolio-textarea-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}

.portfolio-textarea-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}

.portfolio-textarea-container>label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}

.portfolio-textarea-container .portfolio-desc-counter {
    font-size: 12px;
}

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}

.input-error .p-inputtext:hover,
.input-error .p-inputtext:enabled:focus,
.input-error .p-inputtext {
    border-color: #E63E3E;
}

.input-error .input-label {
    color: #E63E3E;
}

.error-message:nth-child(2) {
    padding-top: 10px;
}

.error-message {
    color: #E63E3E;
    padding-left: 10px;
}

.footer-portfolio-button {
    padding: 8px 32px;
    background: #33CC99;
    border: 1px solid #33CC99;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
}

.footer-portfolio-button:hover {
    background: #00C083;
    border-color: #00C083;
}

.footer-portfolio-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}


.group-actions-switch-container {
    display: grid;
    width: 200px;
    margin: 0px 16px 16px;
}

.p-inputswitch {
    margin-left: auto;
    display: flex;
}

.row {
    display: flex;
    align-items: center;
    padding: 4px 0px;
    min-height: 32px;
}

h5 {
    margin: 8px 0;
}

.row :last-child.column {
    justify-content: flex-end;
}

.check-box-row {
    display: flex;
    align-items: center;
    padding: 4px 0px;
}
.check-box-row > div {
    margin-right: 32px;
}
.check-box-row > div:last-child {
    margin-right: 0;
}

.p-checkbox {
    margin-right: 4px !important;
}

.column {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.portfolio-select-button {
    margin-left: auto;
    width: fit-content;
}

.portfolio-textarea-container .info-icon-container i {
    margin-left: auto;
}

.info-icon-container {
    padding: 0 2px 5px;
    display: flex;
}

.info-icon-container i {
    color: #00C083;
}

::v-deep(.portfolio-select-button .p-button) {
    font-size: 0.9rem !important;
    padding: 4px 6px 4px 6px;
}

::v-deep(.portfolio-select-button .p-button:focus) {
    box-shadow: none;
}

.hours-label {
    margin: 0px 4px;
}
</style>