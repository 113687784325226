<template>
    <div class="competitions-list-container">
        <div>
            <template v-for="competition in competitions" :key="competition.contestId">
                <div class="card" >

                    

                    <div class="flex-row">
                        <div class="flex-row" style="width: 40%;">
                            <UserAvatar :imageSrc="competition?.iconUrl" size='lg' shape='roundedSquare'/>
                            <div class="flex-column" style="gap: 8px; padding: 0px 16px 0px;">
                                <span style="font-size: 20px;">
                                    {{ competition?.name }}
                                </span>
                                
                                <span v-if="canAward(competition)">🏁This competition has ended.</span>
                                <div class="flex-row">
                                     <Button @click='goToCompetitionsDetails(competition)' class="green-button__secondary action-button">
                                    <span>Open</span>
                                </Button>
                                 <Button @click='editCompetitionsDetails(competition)' class="green-button__secondary action-button" :disabled="hasEnded(competition)">
                                    <span>Edit</span>
                                </Button>
                                <Button @click='cancelCompetition(competition)' class="green-button__secondary action-button" :disabled="hasStarted(competition)">
                                    <span>Cancel</span>
                                </Button>

                                </div>
                            </div>
                            
                        </div>
                        <div class="data-container" style="width: 60%;">
                            <div>
                                <span>Registration Start: </span>
                                <span>{{ getCompetitionDateFormatted(competition?.registrationStartDate) }}</span>
                            </div>
                             <div>
                                <span>Registration End: </span>
                                <span>{{ getCompetitionDateFormatted(competition?.registrationEndDate) }}</span>
                            </div>
                            <div>
                                <span>Competition Start: </span>
                                <span>{{ getCompetitionDateFormatted(competition?.startDate) }}</span>
                            </div>
                            <div>
                                <span>Competition End: </span>
                                <span>{{ getCompetitionDateFormatted(competition?.endDate) }}</span>
                            </div>
                            <div>
                                <span>Type: </span>
                                <span>{{ getCompetitionType(competition?.competitionTypeId) }}</span>
                            </div>
                            <div>
                                <span>Registration: </span>
                                <span>{{ getCompetitionRegistrationType(competition) }}</span>
                            </div>
                            <div>
                                <span>Skill Level: </span>
                                <span>{{ getCompetitionSkillLevel(competition?.skillLevelId) }}</span>
                            </div>
                            <div>
                                <span>Competition Type: </span>
                                <span>{{ getCompetitionCategory(competition?.categoryId) }}<span v-if="competition?.openPortfolio"> (Open)</span></span>
                            </div>
                            <div>
                                <span>Private: </span>
                                <span>
                                {{competition.privateContest}}
                                   </span> 
                            </div>
                            <div>
                                <span>Currency: </span>
                                <span>
                                {{(competition.currencyId == 1? 'CAD': 'USD')}}
                                   </span> 
                            </div>
                            <div>
                                <span>Starting Cash Balance: </span>
                                <span>
                                {{formatter.format(competition.minStartingCash, '$0,0.00')}}
                                   </span> 
                            </div>
                            <div>
                                <span>Min Short Price: </span>
                                <span>
                                {{((competition.minShortPrice && competition.minShortPrice > 0)? formatter.format(competition.minShortPrice, '$0,0.00') : 'none')}}
                                   </span> 
                            </div>
                            <div>
                                <span>Min Long Price: </span>
                                <span>
                                {{((competition.minLongPrice && competition.minLongPrice > 0)? formatter.format(competition.minLongPrice, '$0,0.00') : 'none')}}
                                   </span> 
                            </div>
                            <div>
                                <span>Trading Delay: </span>
                                <span>
                                {{(competition.maxTradingDelay > 0 ? "On" : 'Off')}}
                                   </span> 
                            </div>
                             <div>
                                <span>Level 2 Competition Trading Data: </span>
                                <span>
                                {{(competition.allowLevel2 > 0 ? "Available" : 'Disabled')}}
                                    </span> 
                            </div>
                            <div v-if="competition.maxContestants">
                                <span>Max Contestants: </span>
                                <span>
                                {{competition.maxContestants}}
                                   </span> 
                               
                            </div>
                            <div v-if="competition.issuePass">
                                <span>This competition includes a Basic Investor Pass to avoid usage limits.</span>
                               
                            </div>
                            <div>
                                <span>Status: </span>
                                <span>
                                {{(competition.statusId == 1 ? "Live" : 'Pending Payment')}}
                                   </span> 
                            </div>

                        </div>
                        <img class="competition-list-icon" src="assets/images/competition.png" v-if="!isMobile"/>
                    </div>
                    <div class="flex-column" style="margin-top: 15px;">
                        <span style="margin-bottom: 5px;"><b>Short Description</b>: {{ competition.shortDescription }}</span>
                        <Accordion>
                            <AccordionTab header="Full Description">

                                    <div v-if="competition.longDescription" class="long-description-container">
                                    <span v-html="competition.longDescription"></span>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>

                </div>
            </template>
            <Paginator :rows="limit" :first="offset" :totalRecords="total" @page="onPage" v-show="total > 0"/>
                <p v-show="total <= 0">You have not hosted any competitions yet.</p>
        </div>
        
    </div>

</template>

<script>
// import Column from 'primevue/column';
// import DataTable from 'primevue/datatable';
import UserAvatar from "../profile/UserAvatar.vue";

// import router from '../../../router';
import { getCompetitionCategory, getCompetitionSkillLevel, getCompetitionRegistrationType, getCompetitionDateFormatted, getCompetitionType } from '../contests/index';
import BrowserUtils from "../../utilities/BrowserUtils";

import { isAfter, startOfDay, add } from 'date-fns';


import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import FormattingUtils from '../../utilities/FormattingUtils';

import Paginator from 'primevue/paginator';

export default {
    name: 'GroupCompetitionsList',
    emits: ['edit-competition', 'cancel-competition', 'change-page'],
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        competitions: {
            type: Array,
            required: false
        },
        limit: {
            type: Number,
            required: true
        },

        offset: {
            type: Number,
            required: true
        },

        total: {
            type: Number,
            required: true
        }
    },
    components: {
        UserAvatar,
        Accordion,
        AccordionTab,
        Paginator,
    },
    data() {
        return {
            getCompetitionCategory, getCompetitionSkillLevel,
            getCompetitionRegistrationType, getCompetitionDateFormatted,
            getCompetitionType,
            formatter: new FormattingUtils(),
            selectedCompetition: null,

        }
    },

    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        },
    },

    methods: {

        onPage(pageState){
            this.$emit('change-page', pageState);
        },

        canAward(competition){
            const now = new Date();
            var b = isAfter(now, startOfDay(add(new Date(competition?.endDate), { days: 1 })));
            
            return b ;
        },

         hasEnded(competition){
            
                const now = new Date();
                var b = isAfter(now, new Date(competition?.endDate));
                
                return b ;
           
        },

        hasStarted(competition) {
           
                const now = new Date();
                var b = isAfter(now, new Date(competition?.startDate));
                
                return b ;
            
        },

        goToCompetitionsDetails(item) {
            this.$router.push('/contests/competitions/' + item.contestId);
        },

        editCompetitionsDetails(competition){
            this.$emit('edit-competition', competition);
        },

       cancelCompetition(competition){
            this.$emit('cancel-competition', competition);
       }
        
    }
}
</script>

<style scoped>
::v-deep(.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus) {

  box-shadow: none;
}

::v-deep(.p-accordion .p-accordion-header .p-accordion-header-link){
    padding: 5px;
}

.competition-list-icon {
    max-width: 5em;
    max-height: 5em;
}
.competitions-list-container {
    display: flex;
    margin: 1rem 1rem 60px 1rem;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

::v-deep(table th),
::v-deep(table td) {
    padding: 0.4rem 0.4rem !important;
}

.hosted-by-label {
    color: #33CC99;
}

.hosted-by-label:hover {
    cursor: pointer;
}

.data-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.data-container * {
    min-width: 222px;
}

.action-button {
    padding: 4px 8px;
    border-style: solid;
    border-radius: 28px;
    text-align: center;
    width: 80px;
    margin-right: 5px;
}

.action-button span {
    width: 100%;
}

@media (max-width: 760px) {
    .competitions-list-container {
        margin-left: 1rem;
    }
}
</style>