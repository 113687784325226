<template>
    <Dialog :header="header" v-model:visible="display" :modal="true"  :draggable='false'   style="max-width:500px;min-width:500px;" closeIcon="false">
        <div class="container">
            <div v-show="!paymentConfirmed">
            <p>Waiting for payment confirmation.</p>
            <p>  This should only take a few moments. </p>
             <ProgressBar mode="indeterminate" style="height: 6px" ></ProgressBar>
            </div>
            <div v-show="paymentConfirmed">
                <p>Payment Successful!</p>
            </div>
        </div>
       
       <template #footer>
            <Button class='cancel-button' label='OK' @click='close()' v-show="paymentConfirmed"/>
       </template>
    </Dialog>

</template>

<script>
import Dialog from 'primevue/dialog';

import ProgressBar from 'primevue/progressbar';

import GroupService from '../../../service/GroupService';


export default {
    name: 'GroupCompetitionPaymentConfirmationModal',
    emits:['competition-payment-confirmed'],
    data() {
        return {
            display: false,
            contestId: null,
            
            confirmed: false,
           
        }
    },
    components: {
        Dialog, 
        ProgressBar,
    },

    computed: {
        paymentConfirmed() {
            return this.confirmed;
        },
        
        header() {
            if( !this.confirmed ){
                return "Confirming Payment";
            }
            else {
                return "Payment Confirmed";
            }
        }

        
    },

    methods: {
        open(contestId) {//eslint-disable-line
            this.confirmed = false;
            this.contestId = contestId;
           
            this.display = true;

           setTimeout(() => {this.doPoll()}, 5000);
        },

       

        doPoll() {
            if( !this.confirmed ){
                console.log("checking if group member")
                GroupService.isGroupContestLive(this.contestId).then(resp=>{
                    if( resp.data.status == "success" && resp.data.live  ) {
                        console.log("payment confirmed")
                        this.confirmed = true;
                        this.$emit('competition-payment-confirmed');
                    }
                    else {
                        setTimeout(() => {this.doPoll()}, 5000);
                    }
                });
            }
        },

         
         close() {

            this.display = false;
            this.contestId= null;
           
            this.confirmed = false;
        },
       

        

    },
}
</script>

<style>

.join-group-modal {
    position: static;
    font-family: "Trebuchet MS", "Verdana";
    width: 600px;
    border-radius: 16px;
}

.join-group-modal .p-dialog-header {
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.join-group-modal .p-dialog-header .p-dialog-title {
    font-size: 24px;
    font-weight: bold;
}

.join-group-modal .p-dialog-content {
    height: 100%;
    overflow-y: hidden;
    border-top: 1px solid #BFBFBF;
    border-bottom: 1px solid #BFBFBF;
    padding: 24px 16px;
}

.join-group-modal .p-dialog-footer {
    text-align: center;
    padding: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
</style>
<style scoped>
.container {
    display: flex;
    flex-direction: column;
    text-align: center;
}



</style>